@import '../styleConstants.scss';

.App {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color:#E0F2F1;
}

.phase-1 {
  background-color:#E0F2F1;
}

.phase-2 {
  background-color: #FFCEB9;  
}

.phase-3 {
  background-color:#FFDCA9;  
}

.safari {
  height: 90vh;
  overflow: hidden;
}