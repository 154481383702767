@import '../../../styleConstants.scss';

.Welcome {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Welcome__logo {
  width: 15rem;
  margin-bottom: 1rem;
}

.Welcome__subtitle {
  width: 90%;
  margin-top: 0;
  margin-bottom: 2rem;
  color: $light-grey;
}