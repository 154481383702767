// Margins
$margin-x-small: 0.5rem;
$margin-small: 1rem;
$margin-medium: 2rem;
$margin-large: 3rem;
$margin-bottom: 10%;
$scene-vertical-margin: 5%;

// Widths
$width-medium: 85%;
$scene-width: 90%;
$scene-height: 80%;

// Colors
$very-light-grey: #ADADAD;
$light-grey: rgba(0,0,0,0.38);
$grey: rgba(0, 0, 0, 0.685);
$button-grey: #607176;
$light-teal: #B2DFDB;
$teal:#80CBC4;
$dark-teal: #4DB6AC;
$very-dark-teal: #00897B;

// Border-radius
$border-radius: 0.5rem;