@import '../../../styleConstants.scss';

.FinishedPage {
  width: $scene-width;
  height: $scene-height;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $light-grey;
}

.FinishedPage__title {
  margin: 0;
  margin-bottom: 1.5rem;
  font-weight: normal;
  font-size: 4rem;
}