@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Rubik);
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  text-align: center;
  font-family: 'Rubik', sans-serif;
  box-sizing: border-box;
  touch-action: none; }

.App {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #E0F2F1; }

.phase-1 {
  background-color: #E0F2F1; }

.phase-2 {
  background-color: #FFCEB9; }

.phase-3 {
  background-color: #FFDCA9; }

.safari {
  height: 90vh;
  overflow: hidden; }

.FeelingPicker {
  width: 90%;
  height: 80%;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; }

.FeelingPicker__title {
  margin-top: 1rem;
  padding: 0;
  font-size: 1.5rem;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.38); }

.FeelingList {
  margin-top: 1rem;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center; }

.Feeling {
  margin: 0.5rem;
  height: 2.5rem;
  width: 6rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #B2DFDB;
  box-shadow: 0px 8px 19px -8px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.Feeling.Feeling--selected {
  background-color: #00897B;
  box-shadow: 0px 8px 19px -8px rgba(0, 0, 0, 0.5); }

.Feeling__button {
  width: 100%;
  height: 100%;
  font-size: 0.8rem;
  color: #607176;
  border: none;
  background-color: inherit;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }

.Feeling__button.Feeling__button--selected {
  color: white; }

.col-0 {
  background-color: #B2DFDB; }

.col-1 {
  background-color: #80CBC4; }

.col-2 {
  background-color: #4DB6AC; }

.Welcome {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.Welcome__logo {
  width: 15rem;
  margin-bottom: 1rem; }

.Welcome__subtitle {
  width: 90%;
  margin-top: 0;
  margin-bottom: 2rem;
  color: rgba(0, 0, 0, 0.38); }

.NextPageButton {
  width: 50%;
  height: 4rem;
  font-size: 1.4rem;
  background-color: #80CBC4;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0px 8px 19px -8px rgba(0, 0, 0, 0.5);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.NextPageButton__button {
  color: #607176;
  width: 100%;
  height: 100%;
  border: none;
  background-color: inherit;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }

.SceneNav-container {
  width: 85%; }

.SceneNav {
  width: 100%;
  margin-bottom: 5%;
  display: flex;
  justify-content: space-between; }

.SceneNav--forwards-only {
  justify-content: flex-end; }

.SceneNav__contact {
  width: 78%;
  font-size: 0.75rem;
  color: #ADADAD; }

.NavButton {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 2rem;
  box-shadow: 0px 9px 19px -5px rgba(0, 0, 0, 0.5);
  background-color: inherit;
  outline: none;
  overflow: hidden; }

.NavButton__button {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #495261;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.NavButton__button:active {
  background-color: #5f6b7e; }

.NavButton__arrow-image {
  font-size: 2rem;
  color: #C7CFD2; }

.StartOverButton {
  width: 4.5rem;
  height: 2.5rem;
  font-size: 0.6rem;
  background-color: #80CBC4;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0px 8px 19px -8px rgba(0, 0, 0, 0.5);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.StartOverButton__button {
  color: #607176;
  width: 100%;
  height: 100%;
  border: none;
  background-color: inherit;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }

.TextInput {
  width: 90%;
  height: 80%;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; }

.TextInputBox {
  width: 100%; }

.TextInputBox__prompt {
  font-size: 1rem;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.38);
  line-height: 23px; }

.TextInputBox__response {
  width: 80%;
  padding: 1rem;
  line-height: 23px;
  background-color: #FFFBFA;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  resize: none;
  box-shadow: 0px 6px 28px 0px rgba(0, 0, 0, 0.45); }

.TextInputBox__response::-webkit-input-placeholder {
  color: #ADADAD;
  font-style: italic; }

.TextInputBox__response::-ms-input-placeholder {
  color: #ADADAD;
  font-style: italic; }

.TextInputBox__response::placeholder {
  color: #ADADAD;
  font-style: italic; }

.TextInputBox__response.size-1 {
  height: 24rem; }

.TextInputBox__response.size-2 {
  height: 8rem; }

.TextInputBox__response.size-3 {
  height: 4.5rem; }

.PhaseTitlePage {
  width: 90%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.38); }

.PhaseTitlePage__title {
  margin: 0;
  margin-bottom: 1rem;
  font-weight: normal;
  font-size: 4rem; }

.PhaseTitlePage__subtitle {
  margin: 0;
  margin-bottom: 5rem;
  font-weight: normal;
  font-size: 2rem; }

.Summary {
  width: 90%;
  padding: 0 5%;
  height: 80%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; }

.Summary__subtitle {
  margin: 1rem 0;
  color: #ADADAD; }

.Summary_paragraph {
  width: 90%;
  margin: 0;
  margin-bottom: 1rem;
  color: rgba(0, 0, 0, 0.685); }

.SelectedFeelings {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center; }

.SelectedFeelings__feeling {
  margin: 0;
  font-size: 1rem; }

.FinishedPage {
  width: 90%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.38); }

.FinishedPage__title {
  margin: 0;
  margin-bottom: 1.5rem;
  font-weight: normal;
  font-size: 4rem; }

