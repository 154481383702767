@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css?family=Rubik");

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  text-align: center;
  font-family: 'Rubik', sans-serif;
  box-sizing: border-box;
  touch-action: none;
}