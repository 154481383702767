@import '../../../../../../../styleConstants.scss';

.Feeling {
  margin: 0.5rem;
  height: 2.5rem;
  width: 6rem;
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #B2DFDB;
  box-shadow:  0px 8px 19px -8px rgba(0,0,0,0.50);
  overflow: hidden;
  user-select: none;
}

.Feeling.Feeling--selected {
  background-color: $very-dark-teal;
  box-shadow:  0px 8px 19px -8px rgba(0,0,0,0.50);
}

.Feeling__button {
  width: 100%;
  height: 100%;
  font-size: 0.8rem;
  color: $button-grey;
  border: none;
  background-color: inherit;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.Feeling__button.Feeling__button--selected {
  color: white;
}

.col-0 {
  background-color: $light-teal;
}

.col-1 {
  background-color: $teal;
}

.col-2 {
  background-color: $dark-teal;
}