@import '../../../styleConstants.scss';

.NextPageButton {
  width: 50%;
  height: 4rem;
  font-size: 1.4rem;
  background-color: $teal;
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow:  0px 8px 19px -8px rgba(0,0,0,0.50);
  user-select: none;
}

.NextPageButton__button {
  color: $button-grey;
  width: 100%;
  height: 100%;
  border: none;
  background-color: inherit;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}