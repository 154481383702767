@import '../../../styleConstants.scss';

.SceneNav-container {
  width: $width-medium;
}

.SceneNav {
  width: 100%;
  margin-bottom: $scene-vertical-margin;
  display: flex;
  justify-content: space-between;
}

.SceneNav--forwards-only {
  justify-content: flex-end;
}

.SceneNav__contact {
  width: 78%;
  font-size: 0.75rem;
  color: $very-light-grey;
}