@import '../../../styleConstants.scss';

.FeelingPicker {
  width: $scene-width;
  height: $scene-height;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.FeelingPicker__title-container {
  
}

.FeelingPicker__title {
  margin-top: $margin-small;
  padding: 0;
  font-size: 1.5rem;
  font-weight: normal;
  color: $light-grey;
}