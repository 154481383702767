.SelectedFeelings {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.SelectedFeelings__feeling {
  margin: 0;
  font-size: 1rem;
}