.NavButton {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 2rem;
  box-shadow:  0px 9px 19px -5px rgba(0,0,0,0.50);
  background-color: inherit;
  outline: none;
  overflow: hidden;  
}

.NavButton__button {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #495261;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.NavButton__button:active {
  background-color: lighten(#495261, 10%);
}

.NavButton__arrow-image {
  font-size: 2rem;
  color: #C7CFD2;
}